<script lang="ts">
  import { login } from "../../utilities";
</script>

<style>
  .ghost {
    opacity: 0.2;
  }

  :global(.dark) .ghost {
    filter: invert(1);
    opacity: 0.1;
  }
</style>

<div class="grid grid-cols-2 w-[800px] m-auto">
  <img class="ghost" src="/ghost.png" alt="Ghost" />
  <div>
    <p><b>Was?</b></p>
    <p>The Haunting Games ist ein Phasmophobia Turnier.<br/>Wer wird der beste Geisterjäger sein?</p>
    <br/>
    <p><b>Wer?</b></p>
    <p>Die einzige Vorraussetzung sind min. 500 Follower, dies gilt natürlich nur für den Team Anführer.</p>
    <br/>
    <p><b>Wann?</b></p>
    <p>Am Sonntag, den 30.06.2024 um 12:00 Uhr.<br/>Das Event wird in etwa 4 Stunden dauern, wovon 3 Stunden Spielzeit angesetzt sind.</p>
    <br/>
    <div class="text-center">
      <button type="button" class="btn btn-md variant-filled-secondary" on:click={login}>
        <span><iconify-icon icon="mdi:twitch"></iconify-icon></span>
        <span>Twitch Login</span>
      </button>
    </div>
  </div>
</div>